/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 .\scene.gltf
Author: MOJackal (https://sketchfab.com/MOJackal)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/spacex-starship-spaceship-f5bbabadb7b44645a7fe9e0e619d555e
Title: SpaceX Starship - Spaceship
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {  useFrame } from "@react-three/fiber";

export function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/scene.gltf')
  // const { actions } = useAnimations(animations, group)
  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    group.current.rotation.y = elapsedTime / 0.5;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} scale={4.24}>
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="bottomrightwing_1" position={[1.09, 1.51, 0]}>
                <mesh name="Object_7" geometry={nodes.Object_7.geometry} material={materials.silver} />
              </group>
              <group name="rightinnerhinge_2" position={[1.09, 0.64, 0.11]} scale={[0.07, 0.06, 0.01]}>
                <mesh name="Object_9" geometry={nodes.Object_9.geometry} material={materials.black} />
              </group>
              <group name="rightouterhinge_3" position={[1.14, 0.64, 0.1]} rotation={[-Math.PI, 1.48, -Math.PI]} scale={[0.07, 0.06, 0.01]}>
                <mesh name="Object_11" geometry={nodes.Object_11.geometry} material={materials.black} />
              </group>
              <group name="toprightwing_4" position={[1.05, 9.27, 0]}>
                <mesh name="Object_13" geometry={nodes.Object_13.geometry} material={materials.silver} />
              </group>
              <group name="bottomleftwing_5" position={[-1.09, 1.51, 0]} rotation={[-Math.PI, 0, 0]} scale={-1}>
                <mesh name="Object_15" geometry={nodes.Object_15.geometry} material={materials.silver} />
              </group>
              <group name="leftinnerhinge_6" position={[-1.09, 0.64, 0.11]} rotation={[-Math.PI, 0, 0]} scale={[-0.07, -0.06, -0.01]}>
                <mesh name="Object_17" geometry={nodes.Object_17.geometry} material={materials.black} />
              </group>
              <group name="leftouterhinge_7" position={[-1.14, 0.64, 0.1]} rotation={[-Math.PI, 1.48, 0]} scale={[-0.07, -0.06, -0.01]}>
                <mesh name="Object_19" geometry={nodes.Object_19.geometry} material={materials.black} />
              </group>
              <group name="topleftwing_8" position={[-1.05, 9.27, 0]} rotation={[-Math.PI, 0, 0]} scale={-1}>
                <mesh name="Object_21" geometry={nodes.Object_21.geometry} material={materials.silver} />
              </group>
              <group name="core_starship_0">
                <mesh name="Object_4" geometry={nodes.Object_4.geometry} material={materials.silver} />
                <mesh name="Object_5" geometry={nodes.Object_5.geometry} material={materials.black} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
